import React from "react"
import Layout from "../components/Layout"

const PoliticaPrivacidad = () => {
  return (
    <Layout>
      <div className="container">
        <div className="text-component margin-y-lg">
          <h2 className="text-md">Polí­tica de privacidad</h2>
          <p>
            De conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de
            27 de abril, relativo a la protección de las Personas fí­sicas en lo
            que respecta al tratamiento de datos personales y a la libre
            circulación de estos datos (en adelante, RGPD) le informamos:
          </p>
          <p className="font-primary-bold">Responsable</p>
          <p>RIEGOS AZUER, S.L.</p>
          <p className="font-primary-bold">Finalidad</p>
          <p>
            Gestión de las solicitudes de información que se realizan a través
            de la página web o el correo electrónico con la finalidad de
            facilitarles la información que nos solicite sobre nuestros
            productos y servicios.
          </p>
          <p className="font-primary-bold">Legitimación</p>
          <p>
            Consentimiento expreso del usuario a través de la selección de la
            casilla check de enví­o del formulario donde verifica su
            consentimiento.
          </p>

          <p>
            Medidas precontractuales en el caso de solicitudes de presupuesto u
            otras informaciones en relación a nuestros productos y servicios.
          </p>
          <p className="font-primary-bold">Destinatarios</p>
          <p>
            No existe ninguna cesión de datos prevista, salvo obligación legal.
          </p>
          <p className="font-primary-bold">Derechos</p>
          <p>
            Podrá ejercitar los derechos de acceso, rectificación, supresión,
            oposición, portabilidad y retirada de consentimiento de sus datos
            personales en la dirección de correo electrónico
            riegosazuer@riegosazuer.com.
          </p>
          <p className="font-primary-bold">Procedencia</p>
          <p>El propio interesado.</p>

          <p>
            De conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de
            27 de abril, relativo a la protección de las personas fí­sicas en lo
            que respecta al tratamiento de datos personales y a la libre
            circulación de estos datos (en adelante, RGPD)
          </p>
          <p className="font-primary-bold">
            ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE SUS DATOS?
          </p>
          <p>Denominación social: RIEGOS AZUER, S.L.</p>
          <p>
            Domicilio social: Calle XV, Parc. 110, 13200, Manzanares, Ciudad
            Real.
          </p>
          <p>CIF: B13051990.</p>
          <p>Dirección de correo electrónico: riegosazuer@riegosazuer.com</p>
          <p className="font-primary-bold">
            ¿CON QUÉ FINALIDAD TRATAMOS SUS DATOS PERSONALES?
          </p>
          <p>
            Sus datos personales sólo se utilizarán para las siguientes
            finalidades:
          </p>
          <ul>
            <li>
              Gestión de las solicitudes de información que se realizan a través
              de la página web o el correo electrónico con la finalidad de
              facilitarles la información sobre nuestros productos y servicios.
            </li>
            <li>
              Gestión precontractual en el caso de que nos solicite presupuesto.
            </li>
          </ul>

          <p className="font-primary-bold">
            ¿CUÁL ES LA LEGITIMACIÓN PARA EL TRATAMIENTO DE SUS DATOS
            PERSONALES?
          </p>
          <p>
            Consentimiento expreso del usuario a través de la selección de la
            casilla check de enví­o del formulario donde verifica su
            consentimiento.
          </p>
          <p>
            Medidas precontractuales en el caso de solicitudes de presupuesto u
            otras informaciones en relación a nuestros productos y servicios.
          </p>
          <p className="font-primary-bold">
            ¿POR CUÁNTO TIEMPO CONSERVARÉ SUS DATOS PERSONALES?
          </p>
          <p>
            Los datos personales que nos proporciona serán conservados mientras
            se mantenga nuestra relación contractual o mercantil. No obstante a
            partir de la fecha de la terminación de nuestra relación contractual
            o mercantil sus datos serán conservados:
          </p>
          <p>
            UN AÑO las derivadas de conexiones realizadas por internet con
            nuestro sitio web, correo electrónico y llamadas telefónicas de voz,
            mensajerí­a instantánea, SMS o MMS
          </p>
          <p className="font-primary-bold">
            ¿CÓMO HE OBTENIDOS SUS DATOS PERSONALES?
          </p>
          <p>Los datos obtenidos no los ha proporcionado usted.</p>
          <p>¿A QUÉ DESTINATARIOS SE COMUNICARÁN SUS DATOS PERSONALES?</p>
          <p>
            No existe ninguna cesión de datos prevista, salvo obligación legal.
          </p>
          <p className="font-primary-bold">
            ¿CUÁLES SON SUS DERECHOS CUANDO ME FACILITA SUS DATOS PERSONALES?
          </p>
          <p>
            Cualquier interesado podrá solicitar el ejercicio de los siguientes
            derechos:
          </p>
          <ul>
            <li>
              Derecho de acceso: El interesado tendrá derecho a obtener
              confirmación de si se están tratando o no datos personales que le
              conciernen.
            </li>
            <li>
              Derecho de rectificación: El interesado tendrá derecho a obtener
              la rectificación de los datos personales inexactos que le
              conciernan o incompletos.
            </li>
            <li>
              Derecho de supresión: El interesado tendrá derecho a obtener la
              supresión de los datos personales que le conciernan cuando los
              datos personales ya no sean necesarios en relación con los fines
              para los que fueron recogidos o tratados de otro modo.
            </li>
            <li>
              Derecho de limitación: Podrá solicitar la limitación del
              tratamiento de sus datos personales, en cuyo caso únicamente los
              conservarí­a para el ejercicio o la defensa de reclamaciones.
            </li>
            <li>
              Derecho a la portabilidad de sus datos: Por lo que puede
              solicitarnos que sus datos personales automatizados sean cedidos o
              transferidos a cualquier otra empresa que nos indique en un
              formato estructurado, inteligible y automatizado.
            </li>
            <li>
              Derecho de retirar el consentimiento: Usted tendrá derecho a
              retirar el consentimiento en cualquier momento, sin que ello
              afecte a la licitud del tratamiento basado en el consentimiento
              antes de su retirada.
            </li>
            <li>
              Derecho de oposición: El interesado tendrá derecho a oponerse al
              tratamiento de sus datos.
            </li>
          </ul>

          <p className="font-primary-bold">
            ¿CÓMO PODRÁ EJERCITAR ESTOS DERECHOS?
          </p>
          <p>
            Ponemos a su disposición si nos lo solicitan los formularios donde
            podrá ejercitar estos derechos. Podrá solicitarnos en el correo
            riegosazuer@riegosazuer.com indicándonos el derecho que quiere
            ejercitar y le enviaremos el formulario correspondiente.
          </p>
          <p>
            El ejercicio de los derechos deberá llevarse a cabo mediante
            comunicación dirigida al correo electrónico
            riegosazuer@riegosazuer.com o bien en el domicilio social en la
            calle XV, Parc. 110, 13200, Manzanares, Ciudad Real.
          </p>
          <p className="font-primary-bold">
            ¿QUIÉN PUEDE EJERCITAR LOS DERECHOS?
          </p>
          <p>
            Los derechos de los interesados son personalí­simos, por lo tanto,
            serán ejercidos por el titular de los datos acreditando debidamente
            su identidad (para ello se le solicitará el DNI o equivalente).
          </p>
          <p>
            También podrá ejercerse a través de representación legal, en cuyo
            caso, además del DNI del interesado o equivalente, habrá de
            aportarse DNI y documento acreditativo auténtico de la
            representación del tercero.
          </p>
          <p className="font-primary-bold">
            ¿CUÁL SERÁ NUESTRA OBLIGACIÓN CUANDO EJERCITA ALGUNO DE LOS
            DERECHOS?
          </p>
          <p>
            El responsable del tratamiento deberá contestar la solicitud que se
            le dirija en todo caso, con independencia de que figuren o no datos
            personales del afectado o interesado en sus tratamientos.
          </p>

          <p>
            En el caso de que la solicitud no reúna los requisitos
            especificados, el responsable del fichero deberá solicitar la
            subsanación de los mismos.
          </p>

          <p>
            El responsable de tratamiento responderá a las solicitudes en el
            plazo de un mes a partir de la recepción de la solicitud. Dicho
            plazo podrá prorrogarse otros dos meses en caso necesario, teniendo
            en cuenta la complejidad y el número de solicitudes. El responsable
            informará al interesado de cualquiera de dichas prórrogas en el
            plazo de un mes a partir de la recepción de la solicitud, indicando
            los motivos de la dilación.
          </p>
          <p className="font-primary-bold">
            DERECHO DE RECLAMACIÓN A LA AUTORIDAD DE CONTROL
          </p>
          <p>
            Usted, podrá solicitar la tutela de derechos que no hayan sido
            debidamente atendidos a la Agencia Española de Protección de datos.
            Bien a través de la sede electrónica de su portal web (www.agpd.es),
            o bien mediante escrito dirigido a su dirección postal (C/Jorge
            Juan, 6, 28001-Madrid).
          </p>
          <p className="font-primary-bold">¿QUÉ INFORMACIÓN RECOPILAMOS?</p>
          <p>
            En general, puede usar el Sitio Web sin facilitar ninguna
            información de carácter personal.
          </p>
          <p>
            Disponemos de varias secciones en nuestra página web en las cuales
            existen formularios de recogida de datos personales que son
            necesarios para poder prestarle los servicios que nos solicita.
            Cuando nos remite alguno de estos formularios solicitándonos
            información, el usuario garantiza la autenticidad, exactitud y
            veracidad de oda la información que nos facilite, comprometiéndose a
            mantener actualizados los datos de carácter personal que nos
            facilite de forma que los mismos respondan, en todo momento, a su
            situación real. El Usuario será el único responsable de las
            manifestaciones falsas o inexactas y de los perjuicios que las
            mismas pudieran causar.
          </p>
          <p>
            Los datos personales que recabamos son: Nombre, email y teléfono.
          </p>
          <p className="font-primary-bold">
            ¿QUÉ MEDIDAS ADOPTAMOS PARA MANTENER SEGURA SU INFORMACIÓN?
          </p>
          <p>
            Aplicamos medidas técnicas y fí­sicas razonables para proteger la
            información que recogemos a través del Sitio Web.
          </p>
          <p className="font-primary-bold">
            PROTECCIÓN DE DATOS EN REDES SOCIALES
          </p>
          <p>
            Utilizamos las cuentas de Facebook y twitter para informar sobre
            nuestras actividades e interactuar con nuestros seguidores. Al
            hacerte seguidor nuestro, consientes que trate los datos personales
            que estén disponibles en tu perfil, exclusivamente para dicha
            finalidad y sólo en el entorno de Facebook y twitter, conforme a sus
            polí­ticas de uso y privacidad.
          </p>
          <p>
            Podrás ejercitar los derechos de acceso, rectificación, supresión,
            limitación, portabilidad de sus datos, retirada del consentimiento y
            oposición en la dirección riegosazuer@riegosazuer.com. Atenderemos
            sus solicitudes en el marco y con las limitaciones derivadas de las
            reglas de funcionamiento establecidas por Facebook y twitter.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default PoliticaPrivacidad
